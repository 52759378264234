import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

// core components

function SectionTeam() {
  return (
    <div className="section section-team cd-section" >
        <Container id="teams">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">
                Our English speaking software developers are all from Latin
                America and willing to work remote or in an offshore setting
              </h2>
              <h5 className="description">
                At Code Latam we take great care to place the software developer
                at the right client. The reason for this is obvious. If the
                customer doesn't get the right developer with the right skills,
                the customer will suffer unnecessary delays and problems in its
                projects. On the other hand it would not be good for Code
                Latam's reputation.
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={require("assets/img/skills.png")} />
                  </a>
                </div>
                <CardBody>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h4">The Right Softskills</CardTitle>
                    </div>
                  </a>
                  <p className="card-description text-center">
                    The following are the most importants soft skills we at Code
                    Latam pay attention to when we hire a software developer. If
                    we find the person lacking in one of theses skills but
                    otherwise excellent we make sure we train the candidate
                    before placing him or her in a project or at a client.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      src={require("assets/img/communication.jpg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h4">Communication</CardTitle>
                    </div>
                  </a>
                  <p className="card-description text-center">
                    One of the most important soft skills for a software
                    developer is communication since it’s helpful not only at
                    work but in your personal life as well. Needless to say, the
                    software developers's english level needs to be at least C2.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={require("assets/img/teamwork.jpg")} />
                  </a>
                </div>
                <CardBody>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h4">Teamwork</CardTitle>
                    </div>
                  </a>
                  <p className="card-description text-center">
                    Although this is related to communication, teamwork in
                    itself is a key software developer soft skill, essential for
                    collaborating with others. Developers communicate constantly
                    with other developers, designers, content writers, and
                    business intelligence analysts, as well.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      src={require("assets/img/problem-solving.png")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h4">
                        Troubleshooting & Problem-solving
                      </CardTitle>
                    </div>
                  </a>
                  <p className="card-description text-center">
                    Troubleshooting and problem-solving are related terms, but
                    while the first is all about identifying problems, the
                    second involves finding the right solution for the problem
                    at hand. The first is necessary for the second to be
                    executed.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      src={require("assets/img/adaptability.png")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h4">Adaptability</CardTitle>
                    </div>
                  </a>
                  <p className="card-description text-center">
                    The tech industry is constantly evolving, which means new
                    tools and techniques are developed every year. For this
                    reason, adaptability is key for any developer who needs to
                    learn new things and adapt to new situations, technologies,
                    languages, software, etc.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      src={require("assets/img/accountability.jpg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h4">Accountability</CardTitle>
                    </div>
                  </a>
                  <p className="card-description text-center">
                    Among several soft skills needed for a software developer,
                    accountability is one of them. Software engineers must hold
                    themselves accountable in terms of how functional is the
                    product they work on and how easy the interface is to use by
                    users and clients.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      src={require("assets/img/emotonial-intelligence.jpg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h4">Emotional intelligence</CardTitle>
                    </div>
                  </a>
                  <p className="card-description text-center">
                    Emotional intelligence is the ability to know and handle
                    your emotions, as well as harness your intuition. People who
                    have a high emotional intelligence are generally in touch
                    with their emotions and can notice them in others.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={require("assets/img/time.jpg")} />
                  </a>
                </div>
                <CardBody>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h4">
                        Time Management and Organization
                      </CardTitle>
                    </div>
                  </a>
                  <p className="card-description text-center">
                    Meeting deadlines under pressure is pretty common for
                    developers. Being able to accurately estimate when you’ll
                    complete each assignment and keep your tasks organized is an
                    important aspect of a developer’s day-to-day work.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      
    </div>
  );
}

export default SectionTeam;
