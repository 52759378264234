import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
// core components

function PrincipalNavbar({ isIndexPage }) {
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [bodyClick, setBodyClick] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);

  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const moveToSection = (event, section) => {
    event.preventDefault();
    document.getElementById(section).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            {window.innerWidth < 600 ? (
              <NavbarBrand id="navbar-brand" className="marquee" to="/" tag={Link}>
                {/* <marquee id="mymarquee" width="175px" direction="Left">
                </marquee> */}
                  Code Latam: Latin american software developers
              </NavbarBrand>
            ) : (
              <></>
            )}
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <NavbarBrand id="navbar-brand" to="/" tag={Link}>
              {/* <marquee id="mymarquee" width="175px" direction="Left">
              </marquee> */}
                Code Latam: Latin american software developers
            </NavbarBrand>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle to="/about-us" tag={Link} color="default" nav>
                  About Us
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  to="/#contact-us"
                  tag={Link}
                  onClick={(e) =>
                    isIndexPage ? moveToSection(e, "contact-us") : null
                  }
                  color="default"
                  nav
                >
                  Contact Us
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  Sections
                </DropdownToggle>
                <DropdownMenu className="dropdown-success">
                  <DropdownItem
                    to="/#headers"
                    onClick={(e) =>
                      isIndexPage ? moveToSection(e, "headers") : null
                    }
                    tag={Link}
                  >
                    Code Latam
                  </DropdownItem>
                  <DropdownItem
                    to="/#developers-startups"
                    onClick={(e) =>
                      isIndexPage ? moveToSection(e, "developers-startups") : null
                    }
                    tag={Link}
                  >
                    Developers For Startups
                  </DropdownItem>
                  <DropdownItem
                    to="/#technology-stack"
                    onClick={(e) =>
                      isIndexPage ? moveToSection(e, "technology-stack") : null
                    }
                    tag={Link}
                  >
                    Technology Stack
                  </DropdownItem>
                  <DropdownItem
                    to="/#teams"
                    onClick={(e) =>
                      isIndexPage ? moveToSection(e, "teams") : null
                    }
                    tag={Link}
                  >
                    Our Team
                  </DropdownItem>
                  <DropdownItem
                    to="/#courses"
                    onClick={(e) =>
                      isIndexPage ? moveToSection(e, "courses") : null
                    }
                    tag={Link}
                  >
                    Courses For Developers
                  </DropdownItem>
                  <DropdownItem
                    to="/#time-support"
                    onClick={(e) =>
                      isIndexPage ? moveToSection(e, "time-support") : null
                    }
                    tag={Link}
                  >
                    All Time Support
                  </DropdownItem>
                  <DropdownItem
                    to="/#articles"
                    onClick={(e) =>
                      isIndexPage ? moveToSection(e, "articles") : null
                    }
                    tag={Link}
                  >
                    Articles
                  </DropdownItem>
                  <DropdownItem
                    to="/#green-developers"
                    onClick={(e) =>
                      isIndexPage ? moveToSection(e, "green-developers") : null
                    }
                    tag={Link}
                  >
                    Green Developers
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default PrincipalNavbar;
