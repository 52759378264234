import { useEffect } from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import Navbar from "components/Navbars/PrincipalNavbar";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

function FullStackArticle() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });

  return (
    <>
      <Navbar isIndexPage={false} />
      <AlternativeHeader
        title="The full stack software developer."
        imageUrl={`url(${require("assets/img/sections/full-stack.jpg")})`}
      />
      <div className="main">
        <div className="section">
          <Container style={{ "textAlign": "justify" }}>
            <p>
              Among the different types of jobs with more demand in the USA and
              Europe, those related to Information and Communication
              Technologies stand out. This is also true for Latin America. The
              technology sector in the world seems to be understaffed. There are
              some 120,400 vacancies in Colombia alone for example.
            </p>
            <p>
              Within this sector, the role of the Software Developer is one of
              the profiles that will remain on the rise in terms of demand.
              Specifically, this profession is among the 10 most in demand this
              year in many countries.
            </p>
            <p>
              It is also noteworthy that the professional area with the most
              obstacles to overcome when it comes to finding professionals is
              software development. This is because it specializes in the
              finding of solutions through the use of advanced software coding
              skills and a host of soft skills too.
            </p>
            <p>
              The software developer profile is a profile that encompasses other
              more specific profiles, among which one stands out for its
              all-round competence in the world of programming: The full-stack
              developer.
            </p>
            <p>
              The full stack developer stands among the top ten most
              sought-after professionals. It is a necessary role in all those
              businesses that requires professionals with comprehensive
              knowledge of development, maintenance and adaptation of the
              company's website, and internal/external applications. It is
              therefore, a crucial piece in the business success formula.
            </p>
            <p>
              The skills of a full-stack developer allows him/her to work both
              on the client side and on the server side of an application or
              website. This means that he/she is someone who knows how to handle
              both the part of the web that users interact with and the part
              that is invisible to the user, but key to the proper functioning
              of the web.
            </p>
            <p>
              Their versatility within software, application and web development
              makes these professionals highly demanded and economically valued.
              In fact, according to Deusto Formación, the average salary of a
              junior full-stack developer floats around 60.000 USD per year.
              This figure can vary greatly depending on the developer's
              experience and country of deployment.
            </p>
            <h4>Training</h4>
            <p>
              The training of this type of profiles is important because of the
              existing demand, but also because it is a speciality that is
              included within the Information and Communication Technologies
              (ICT) sector, which is constantly evolving and changing.
            </p>
            <p>
              Currently, there are many training centers that offer the
              possibility of starting a career as a full-stack developer. A
              clear example is Deusto Formación and the launch of its new
              Full-Stack Developer Advanced Course, which will provide the
              opportunity to master the different programming languages and
              existing database systems.
            </p>
            <h4>Eagerness to learn</h4>
            <p>
              Being a good full-stack developer requires being constantly
              updated on new tools and trends that appear in the world of
              software development. Being skilled in all the tasks that this
              type of work requires will make the developer a very interesting
              person to count on.
            </p>
            <p>
              Starting from scratch as a full-stack developer is complicated if
              there is no previous background in coding.
            </p>
            <p>
              Basic concepts like the difference between front end and back end,
              along with problem solving skills are a first step to start on
              your journey.
            </p>
            <h4>Technical know how</h4>
            <p>
              Being a full stack developer does not mean that you know every
              programming language there is, or that you know how to configure
              everything in a cloud system and besides that also know the ins
              and outs of every database system on earth. The knowledge of a
              full stack engineer cuts horizontally across the technology stack
              and is not all encompassing. The particular cut depends of the
              sector and the scope and size of the software system and least of
              not all the preference of the development team or project
              managers. That is why you can have two full stack software
              developers each knowing completely different things.
            </p>
            <p>Looking at the figure below one can see how this works.</p>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default FullStackArticle;
