import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

function ArticlesSection() {
  return (
    <div className="projects-3" >
      <Container id="articles">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h6 className="category">Articles</h6>
            <h2 className="title">
              Articles by Code Latam's offshore-remote software developers
              services administrators in Latin America
            </h2>
            <p>
              Walking on water and developing software from a specification are
              easy if both are frozen.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Link to="/great-offshoring">
              <Card className="card-profile card-plain">
                <CardImg top tag="div">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/sections/work-from-home.jpg")}
                  />
                </CardImg>
                <CardBody>
                  <h6 className="card-category">Remote Work</h6>
                  <CardTitle tag="h4">The Great Offshoring</CardTitle>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col md="4">
            <Link to="/full-stack-develoer">
              <Card className="card-profile card-plain">
                <CardImg top tag="div">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/sections/full-stack.jpg")}
                  />
                </CardImg>
                <CardBody>
                  <h6 className="card-category">Software Development</h6>
                  <CardTitle tag="h4">The Full Stack Developer</CardTitle>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col md="4">
            <Link to="/front-end-develoer">
              <Card className="card-profile card-plain">
                <CardImg top tag="div">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/sections/front-end-developer.jpg")}
                  />
                </CardImg>
                <CardBody>
                  <h6 className="card-category">Software Development</h6>
                  <CardTitle tag="h4">The Front End Developer</CardTitle>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col md="4">
            <Link to="/back-end-develoer">
              <Card className="card-profile card-plain">
                <CardImg top tag="div">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/sections/back-end-developer.jpg")}
                  />
                </CardImg>
                <CardBody>
                  <h6 className="card-category">Software Development</h6>
                  <CardTitle tag="h4">The Back End Developer</CardTitle>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col md="4">
            <Link to="data-base-administrator">
              <Card className="card-profile card-plain">
                <CardImg top tag="div">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/sections/databases.jpg")}
                  />
                </CardImg>
                <CardBody>
                  <h6 className="card-category">Databases</h6>
                  <CardTitle tag="h4">The Database Admin</CardTitle>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col md="4">
            <Link to="remote-software-development">
              <Card className="card-profile card-plain">
                <CardImg top tag="div">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/sections/rawpixel-coms.jpg")}
                  />
                </CardImg>
                <CardBody>
                  <h6 className="card-category">Remote Work</h6>
                  <CardTitle tag="h4">Remote Software Development</CardTitle>
                </CardBody>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ArticlesSection;
