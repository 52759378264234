import axios_core from "axios";
import { global } from "global.js";

export async function sendContactEmail(credentials) {
  const globalLocal = global();
  const axios = axios_core.create({
    baseURL: globalLocal.SERVER_NAME,
    headers: globalLocal.HEADERS,
  });

  const response = await axios.post(
    `${globalLocal.API_EMAILS}/contact`,
    credentials
  );
  return response.data;
}
