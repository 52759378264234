import { useEffect } from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components
import Navbar from "components/Navbars/PrincipalNavbar";

function Error404() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("error-404");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("error-404");
    };
  });
  return (
    <>
      <Navbar isIndexPage={false} />
      <div
        className="background-img"
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/martin-knize.jpg") + ")",
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <h1 class="title">
              404
              <br />
              <p class="error-msg">The page you requested could not be found</p>
            </h1>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Error404;
