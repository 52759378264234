import React from "react";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function SectionDevelopers() {
  return (
    <div className="projects-3" id="developers-startups">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <h2 className="title">Cheap software development for startups.</h2>
            <br />
            <Card className="card-plain card-blog">
              <Row>
                <Col md="4">
                  <div className="card-image" >
                    <img
                      alt="..."
                      className="img"
                      src={require("assets/img/sections/business-deal.webp")}
                    />
                  </div>
                </Col>
                <Col md="8" style={{ "textAlign": "justify" }}>
                  <h5 className="description">
                    Being a startup ourselves here at Code Latam we know how
                    tough it is to get your business going without a load of
                    cash. That is why we offer a unique service to startups. You
                    can pay us less for software development in the beginning
                    while you're still in the growing phase and more later on
                    when when your startup is more mature or when you get more
                    funding. We are also open for deals in which we exchange
                    software development Services in exchange for some equity in
                    your startup.
                  </h5>
                  <h5 className="description">
                    Regular prices start as low as USD 25.00 Our Software
                    developers are second to none and will develop any IT
                    solution you need, be it internal applications for your
                    employees, web portals for your clients or mobile
                    applications, we can make it happen for you at a fraction of
                    the usual cost. If you are technology savy, take a look at
                    the technology stack we support and contact us now, to make
                    use of this unique opportunity.
                  </h5>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionDevelopers;
