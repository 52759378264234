import { useEffect } from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import Navbar from "components/Navbars/PrincipalNavbar";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import { Link } from "react-router-dom";

function GreenDevelopers() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });

  return (
    <>
      <Navbar isIndexPage={false} />
      <AlternativeHeader
        title="Green Software Developers in Latin America. Stopping Climate Change is important to us."
        imageUrl={`url(${require("assets/img/green-software-header.jpg")})`}
      />
      <div className="main">
        <div className="section">
          <Container style={{ "textAlign": "justify" }}>
            <h3 className="description">
              At code Latam we adhere as much as possible to the eight
              principles of green software development:
            </h3>
            <p>
              <ol>
                <li>
                  <p>
                    Carbon. Developers should build software that offers value
                    to users, while producing fewer carbon emissions.
                  </p>
                </li>
                <li>
                  <p>
                    Electricity. Energy-efficient applications use software
                    components that don't consume much energy.
                  </p>
                </li>
                <li>
                  <p>
                    Carbon intensity. Intensity refers to the amount of carbon
                    emissions that is generated for every kilowatt per hour of
                    electricity that's used. Companies should consume as much
                    electricity as possible from renewable energy sources, which
                    have lower carbon intensity.
                  </p>
                </li>
                <li>
                  <p>
                    Embodied, or embedded, carbon. Embodied carbon is how much
                    carbon is released when companies develop and dispose of
                    electronic devices. This principle calls for organizations
                    to build software that reduces the amount of carbon that's
                    released when they develop and dispose of electronic
                    devices.
                  </p>
                </li>
                <li>
                  <p>
                    Energy proportionality. Energy proportionality is the
                    relationship between how much a device is used and the
                    electricity the device uses. The more someone uses a device,
                    the better it can convert electricity. The goal of this
                    principle is to maximize the energy efficiency of the
                    hardware by ensuring a high rate of utilization.
                  </p>
                </li>
                <li>
                  <p>
                    Networking. Sent and received data travels across multiple
                    devices that are connected in the network, including
                    routers, switches and servers. Each of these devices
                    contains embedded carbon and uses electricity. The objective
                    is to decrease carbon emissions and increase the energy
                    efficiency of the software by reducing the size of the data,
                    as well as how far it must travel across the network.
                  </p>
                </li>
                <li>
                  <p>
                    Demand shaping. This describes moving the demand for
                    computing power to another time or region and shaping it to
                    match the supply that's available.
                  </p>
                </li>
                <li>
                  <p>
                    Measurement and optimization. Implementation of long-term,
                    consistent optimization strategies can boost the overall
                    carbon efficiency of software.
                  </p>
                </li>
              </ol>
            </p>
            <h4>Software and the climate problem</h4>
            <p>
              There are two broad ways of looking at software: software as part
              of the climate problem and software as part of the climate
              solution. Building green software, and doing it to scale requires
              the creation of a trusted ecosystem of people, standards, tooling
              and best practices. Part of the he mission of Code-Latam is
              exactly that.
            </p>
            <h4>Carbon</h4>
            <p>
              We want software to become part of the climate solution, rather
              than be part of the climate problem. This is why we are focusing
              on reducing the negative impacts of software on our climate by
              reducing the carbon emissions that software is responsible for
              emitting. Software can also be an enabler of climate solutions.
              Software can be built to help accelerate decarbonization across
              all sectors in industry and society. We need people and
              organizations to focus on both aspects: of making green software
              and green-enabling software. An important part of Code-Latam's
              focus is on creating an ecosystem for developing green software.
              Code-Latam is in the business of building software but we are also
              tasked with giving our clients answers about what they can do to
              reduce the software emissions they are responsible for.
            </p>
            <h4>Reduction</h4>
            <p>
              Our focus is reduction, not neutralization. One gram of carbon not
              emitted into our atmosphere is not the same as one gram of carbon
              that has been offset. By far the more preferable goal is to never
              have emitted the carbon in the first place. Reduction is more
              challenging than offsetting. It entails more risk and more
              investment. To mitigate that risk and incentive investment into
              reduction, we need to grow an ecosystem of people, standards,
              tooling, and best practices for the reduction of carbon emissions
              of software.
            </p>
            <h4>Action</h4>
            <p>
              Our view is that there are only three actions that reduce the
              carbon emissions of software:
              <ol>
                <li>Using less physical resources.</li>
                <li>Using less energy.</li>
                <li>Using energy more intelligently.</li>
              </ol>
              Using energy more intelligently means either consuming
              lower-carbon sources of energy or consuming electricity in a way
              that helps accelerate the energy transition towards a low carbon
              future. Everything that you can do that reduces the carbon
              emissions of software falls into one or more of the above
              categories. If you need to go more in depth with this topic,
              please visit our friends at the{" "}
              <Link to="https://greensoftware.foundation/" target="_blank">
                Green Software Foundation.
              </Link>
            </p>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default GreenDevelopers;
