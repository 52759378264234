import { useEffect } from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import Navbar from "components/Navbars/PrincipalNavbar";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

function DBAdminArticle() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });

  return (
    <>
      <Navbar isIndexPage={false} />
      <AlternativeHeader
        title="The Database Administrator (DBA)"
        imageUrl={`url(${require("assets/img/sections/databases.jpg")})`}
      />
      <div className="main">
        <div className="section">
          <Container style={{ "textAlign": "justify" }}>
            <p>
              A database administrator (DBA) is the information technician
              responsible for directing and performing all activities related to
              maintaining a successful database environment. A DBA makes sure an
              organization's databases and related applications operate
              functionally and efficiently.
            </p>
            <h4>Importance of a DBA</h4>
            <p>
              If your organization uses a database management system (DBMS) for
              mission-critical workloads, it's important to have on board one or
              more database administrators to ensure that applications have
              ongoing, uninterrupted access to data. Most modern organizations
              of every size use at least one DBMS, and therefore the need for
              database administrators is greater today than ever before.
            </p>
            <p>
              The DBA is responsible for understanding and managing the overall
              database environment. By developing and implementing a strategic
              blueprint to follow when deploying databases within their
              organization, DBAs are instrumental in the ongoing efficacy of
              applications that rely on databases for data storage and access.
            </p>
            <p>
              Without the DBA's oversight, application and system outages,
              downtime, and slowdowns will inevitably occur. These kinds of
              problems result in business outages that can negatively affect
              revenue, customer experiences and business reputation.
            </p>
            <p>
              DBAs are the subject matter experts for database management
              systems and all related topics, including DBMS implementation and
              configuration, database design, SQL coding, data extraction,
              transformation and loading (ETL), test data management, problem
              resolution, data security and integrity, database security,
              performance management, optimization, and database backup and
              recovery.
            </p>
            <p>
              Database administration is a vital component of the IT environment
              for any organization that relies on one or more database
              management systems.
            </p>
            <h4>DBA roles and responsibilities</h4>
            <p>
              A DBA can, and does, assume many different roles and
              responsibilities within the IT department involving database
              systems and applications. When adopting a new DBMS, the DBA is
              responsible for designing, implementing and maintaining the
              database system. Often, that includes installing the DBMS and
              setting up the IT infrastructure to allow applications to access
              databases. For a cloud database implementation, the DBA isn't
              responsible for installation, but must orchestrate the proper
              configuration, access and deployment options for their
              organization's usage of the cloud database.
            </p>
            <p>
              The DBA also must establish policies and procedures pertaining to
              the management, security, maintenance and use of the database
              management system. The DBA group creates training materials and
              instructs employees in the proper usage and access of the DBMS.
            </p>
            <p>
              When problems arise, the DBA is the focal point for resolution,
              including troubleshooting, root cause analysis, fine tuning and
              optimizing the performance of tasks and programs that access the
              database.
            </p>
            <p>
              The DBA is responsible for ensuring that databases and data are
              backed up appropriately and can be recovered correctly and quickly
              in the event of failure. Furthermore, the DBA ensures that
              databases are protected and secured, enacts measures to maintain
              the database integrity in terms of data accuracy and makes sure
              unauthorized users can't access the data.
            </p>
            <p>
              The DBA frequently gets pulled into other projects as a subject
              matter expert on the database. Because databases are at the center
              of most modern application development projects, the DBA
              participates in database integration and use in a variety of IT
              projects. This exposure to many different technologies and
              experiences can make the DBA a valuable IT technician not just for
              database-related issues, but for other technologies as well.
            </p>
            <p>
              Additionally, DBAs must be good communicators. They need to be
              able to converse and work with application programmers, business
              end users, IT and business managers, data analysts and other DBAs.
            </p>
            <h4>Types of DBAs</h4>
            <p>
              Many different types of DBAs exist, the most common type being the
              general-purpose DBA, who performs all types of administrative and
              data-related work. However, it's not uncommon for DBAs to focus on
              specific problem domains. A DBA, for example, may focus entirely
              on database design, perhaps broken into logical design and
              physical design, specialize in building systems, concentrate on
              maintaining and tuning existing systems, or center attention on
              narrow areas of database management and administration.
            </p>
            <p>
              Within larger organizations, DBA responsibilities typically are
              split into separate types of roles. Beyond general-purpose, the
              primary roles include system DBA, database architect, database
              analyst, application DBA, task-oriented DBA, performance analyst,
              data warehouse administrator and cloud DBA.
            </p>
            <h4>System DBA.</h4>
            <p>
              In this role, the focus is on technical, rather than business,
              issues. The system DBA is knowledgeable in the arcane technical
              details of how the database is installed, configured and modified.
              Typical tasks center on the physical installation and performance
              of the DBMS software and can include the following:
              <ol>
                <li>Installing new versions and applying fixes;</li>
                <li>Setting and tuning system parameters;</li>
                <li>
                  Tuning the operating system, network and transaction
                  processors to work with the DBMS;
                </li>
                <li>
                  Ensuring appropriate storage and memory are available for the
                  DBMS.
                </li>
              </ol>
              System DBAs are rarely involved with the actual database and
              application implementation. They may get involved in application
              tuning when operating system parameters or complex DBMS parameters
              need to be altered.
            </p>
            <h4>Database architect.</h4>
            <p>
              Primary responsibility is the design and implementation of new
              databases. The database architect designs new databases and
              structures for new and existing applications and is rarely
              involved in the maintenance and tuning of established databases
              and applications. Typical tasks include the following:
              <ol>
                <li>Modeling logical data;</li>
                <li>
                  Translating logical data models into a physical database
                  design;
                </li>
                <li>
                  Analyzing data access requirements to ensure optimal database
                  design and efficient SQL access;
                </li>
                <li>
                  Creating backup and recovery strategies for new databases.
                </li>
              </ol>
            </p>
            <h4>Database analyst.</h4>
            <p>
              Sometimes junior DBAs are referred to as database analysts. The
              database analyst's role may be similar to that of the database
              architect. The database analyst designation may just be another
              name for a database administrator.
            </p>
            <h4>Application DBA.</h4>
            <p>
              Focus is on database design and the ongoing database support and
              administration for a specific application or subset of
              applications. The application DBA is more likely an expert in
              writing and debugging complex SQL and will understand the best
              ways to incorporate database requests into application programs.
              Furthermore, application DBAs typically are responsible for
              managing and refreshing test data for application development
              teams.
            </p>
            <p>
              Not every organization has an application DBA on staff. In that
              case, the general-purpose DBA supports specific applications while
              also maintaining the organization's database environment. But even
              with an application DBA on board, general-purpose DBAs are still
              required to support the overall database environment and
              infrastructure.
            </p>
            <h4>Task-oriented DBA.</h4>
            <p>
              This specialized DBA focuses on a specific administrative task and
              is uncommon outside of large IT shops. A backup-and-recovery DBA,
              for example, would be task-oriented to ensure the organization's
              databases are recoverable, including creating backup plans,
              building and testing backup scripts, testing recovery scripts and
              driving recovery tasks when required. The backup-and-recovery DBA
              also participates in building and testing disaster contingency
              plans for the company's databases.
            </p>
            <h4>Performance analyst.</h4>
            <p>
              As the most common task-oriented DBA, the performance analyst
              focuses entirely on monitoring and improving the performance of
              applications that access databases. A performance analyst is an
              expert in SQL coding for performance and knowledgeable in
              designing and building high-performance databases. Performance
              analysts should have a deep understanding of the DBMS, collaborate
              with other DBAs to implement appropriate changes when required and
              communicate with application developers in their language to
              facilitate appropriate program changes for performance.
            </p>
            <h4>Data warehouse administrator.</h4>
            <p>
              This fully capable DBA has the knowledge and skills to monitor and
              support the data warehouse environment. Data warehouse
              administrators understand the differences between a database that
              supports online transaction processing (OLTP) and a data warehouse
              and must have experience in the following areas:
              <ol>
                <li>Business intelligence (BI) and query tools;</li>
                <li>Specialized database design for data warehousing;</li>
                <li>ETL skills;</li>
                <li>
                  Knowledge of data warehousing technologies, such as online
                  analytical processing (OLAP) and star schema.
                </li>
              </ol>
            </p>
            <h4>Cloud DBA.</h4>
            <p>
              As companies increasingly migrate workloads to the cloud, the
              cloud DBA has become more popular and performs many of the same
              tasks as a general-purpose DBA, but for cloud database
              implementations such as on AWS and Microsoft Azure. The cloud DBA
              understands the services the cloud provider offers, including
              backup and security, to implement databases in the cloud. Cloud
              DBAs need to be aware of latency, fault tolerance and especially
              cost management because adding data or workloads to a cloud
              implementation can significantly increase costs.
            </p>
            <h4>Data, database and system administrators</h4>
            <p>
              Although similar, the roles and responsibilities for a data
              administrator, system administrator and database administrator are
              different.
            </p>
            <p>
              Data administration separates the business aspects of data
              management from the technology. The data administrators are
              aligned more with the business than with IT, translate business
              lexicon into a logical data model and work with the DBA to
              translate models into actual databases.
            </p>
            <p>
              System administrators are responsible for the DBMS installation,
              configuration and setup but typically have no responsibility for
              database design and support. They ensure the IT infrastructure is
              conducive to database development by setting up the DBMS
              appropriately, applying ongoing maintenance from the DBMS vendor
              and coordinating migration to new DBMS releases and versions.
            </p>
            <p>
              These duties typically become the responsibility of DBAs in
              organizations without data and system administrators.
            </p>
            <h4>Functions of database administrators</h4>
            <p>
              The primary function of a DBA is to implement, maintain, structure
              of a database management system.
            </p>
            <p>
              As the central point of contact for information about the
              company's database management systems, DBAs must keep up to date
              on the latest versions and capabilities of each DBMS. They also
              must educate and inform application developers and other users
              about how to use the DBMS and its facilities.
            </p>
            <p>
              In addition, DBAs work with application developers to ensure
              accurate and efficient application design for database access. DBA
              tasks include interfacing SQL with traditional programming
              languages, selecting the type of SQL to use, using middleware and
              APIs such as REST, ODBC, JDBC and SQLJ effectively, defining
              transactions and determining the appropriate use of frameworks
              such as Java EE and .NET. At times, DBAs may be asked to modify or
              write application code to help development projects.
            </p>
            <p>
              Database performance is the optimization of resource usage to
              increase throughput and minimize contention, enabling the largest
              possible workload to be processed. Ensuring efficient performance
              of the database and applications that access it is a core function
              of database administration.
            </p>
            <p>
              When confronted with performance problems, the DBA must be capable
              of performing root cause analysis -- identifying the cause of the
              problem so it can be resolved. This task requires the ability to
              locate bottlenecks and points of contention, monitor workload and
              throughput, review SQL performance and optimization, monitor
              storage space and fragmentation, and view and manage the system
              and DBMS resource use.
            </p>
            <p>
              Administering a DBMS usually requires additional tools than those
              provided with the DBMS itself. DBAs must understand the strengths
              and weaknesses of native DBA tools, develop a strategy for
              addressing the weaknesses and implement tools that improve the
              performance, availability, administration and recovery of the
              databases they manage.
            </p>
            <h4>Educational requirements for DBAs</h4>
            <p>
              The database administrator is expected to stay abreast of emerging
              technologies and new design approaches. Typically, a DBA has a
              bachelor's degree in computer science or information science from
              an accredited university or college, as well as some on-the-job
              training with a specific database product. In some cases, DBAs may
              not be required to have a bachelor's degree if they have extensive
              information technology work experience.
            </p>
            <p>
              Many DBAs have prior experience as application programmers and
              excelled at accessing databases using SQL embedded in COBOL, Java,
              C or other popular programming languages. DBAs are expected to be
              proficient in writing and debugging SQL.
            </p>
            <p>
              A DBA is usually expected to have experience with one or more of
              the major database management products, including the following:
              <ol>
                <li>IBM Db2</li>
                <li>Microsoft SQL Server</li>
                <li>MongoDB</li>
                <li>Oracle</li>
                <li>PostgreSQL</li>
                <li>Redis</li>
                <li>MySQL</li>
              </ol>
              DBAs may also possess in-depth technical skills in related
              technologies such as DevOps software like Docker, Kubernetes and
              Git, ERP packages like SAP, operating systems like Linux and z/OS
              and storage software.
            </p>
            <p>
              Certification programs are available for most of the popular DBMS
              platforms, and many organizations expect DBAs to be certified in
              the database systems they manage. Although not as important as
              on-the-job experience, DBAs who have kept up to date with their
              certification should have relevant skills and knowledge about the
              features, functions and capabilities of the DBMS they're certified
              in.
            </p>
            <h4>Salary range and employment outlook for DBAs</h4>
            <p>
              A database administrator's job can be rewarding and
              well-compensated. According to the U.S. Bureau of Labor
              Statistics, the median annual wage for a DBA is $98,860, but
              salaries can range from a low of $54,070 to a high of $155,660
              depending on factors such as experience, geographical location and
              industry.
            </p>
            <p>
              Another important consideration is employability. According to the
              U.S. Bureau of Labor Statistics Occupational Outlook Handbook,
              "Employment of database administrators is projected to grow 8%
              from 2020 to 2030, about as fast as the average for all
              occupations".
            </p>
            <p>
              Database administration as a career is under pressure from cloud
              computing services, such as the DBaaS model, that provide some of
              the tasks heretofore provided primarily by DBAs, including
              database installation, provisioning, patching and some types of
              backup and performance monitoring. As a result, database
              administrators are increasingly more focused on applications than
              systems, because cloud service providers typically don't offer
              application-level services. In addition, DBAs are spending more
              time interfacing with developers, managing test data, resolving
              problems and optimizing performance than they do on installation
              and patching.
            </p>
            <h4>Do you need a DBA when your database is in the cloud?</h4>
            <p>
              Another nuance is the misconception that organizations don't need
              DBAs when they move data to the cloud. As DBA requirements shift
              more toward application support than system support, failing to
              staff DBAs for a cloud database infrastructure can result in
              inefficient applications, insecure data and perhaps exorbitant
              cloud service provider costs.
            </p>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default DBAdminArticle;
