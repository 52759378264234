export const global = (token) => {
  const SERVER_NAME = "https://code-latam-back.azurewebsites.net";
  return {
    SERVER_NAME,
    HEADERS: {
      "Content-Type": "application/json",
    },
    PRIVATE_HEADERS: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },

    API_EMAILS: `${SERVER_NAME}/send-emails`,
  };
};
