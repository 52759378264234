import { useEffect } from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import Navbar from "components/Navbars/PrincipalNavbar";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

function AboutUs() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <Navbar isIndexPage={false} />
      <AlternativeHeader
        title="Hello, We are Code Latam."
        subTitle="A Latin American offshore society of experienced English speaking
              Software Developers.."
        imageUrl={`url(${require("assets/img/about-us-header.webp")})`}
      />
      <div className="main">
        <div className="section">
          <Container style={{ "textAlign": "justify" }}>
            <h3 className="title-uppercase">
              We
              <i className="fa fa-heart heart mr-3 ml-1" />
              what we do.
            </h3>
            <p>
              If you are an organization looking to outsource part or all of
              your software development, you are at the right place. If you are
              a talented software developer from latin america ready to join an
              exclusive team of service providers for all over the world, you
              are at the right place too. Code Latam's software engineers
              supports software engeneering projects using a broad array of
              technologies and best practices management tools across all time
              zones. We accept only the best. And we are not only talking about
              tech skills. Our vetting process is extensive. However do not get
              scared. If you are good, you are good. Feel free to browse our
              site for the information you need or contact us through our
              contact forms or book a video conference with us if you are an
              organization looking for solid solutions.
            </p>
            <h3 className="title-uppercase">We build great products.</h3>
            <p>
              We offer companies from all over the world the benefits of
              outsourcing their software development projects, using a variety
              of technologies, in different time zones , speeding up the
              delivery process. When you sleep we work. Our project managers
              will make sure you get just what you want in the agreed upon time
              and in budget while protecting your intelectual property with a
              non disclosure agreement.
            </p>
            <p>
              Alternatively if you just want the expertise and you want to
              manage the project yourself, that's fine too. Once you contact us
              we will send you relevant profiles of our software
              developer/engineers in 24 hours. Because our software developer
              are all from Latin America you will get a 35% better ROI than
              elsewhere. Are you on a limited budget and your organization is a
              non profit that work for the public good? Check out our Pro Bono
              Service section. And last but not least. We care for the planet.
              That is why we endorse the principles of green software
              engineering. Contact us and let us know how we can help you
              company grow responsibly with advanced software solutions.
            </p>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default AboutUs;
