import { useEffect } from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import Navbar from "components/Navbars/PrincipalNavbar";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

function BackEndArticle() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });

  return (
    <>
      <Navbar isIndexPage={false} />
      <AlternativeHeader
        title="The Back End software developer."
        imageUrl={`url(${require("assets/img/sections/back-end-developer.jpg")})`}
      />
      <div className="main">
        <div className="section">
          <Container style={{ "textAlign": "justify" }}>
            <p>
              Among the different types of jobs with more demand in the USA and
              Europe, those related to Information and Communication
              Technologies stand out. This is also true for Latin America. The
              technology sector in the world seems to be understaffed. There are
              some 120,400 vacancies in Colombia alone for example.
            </p>
            <p>
              Within this sector, the role of the Software Developer is one of
              the profiles that will remain on the rise in terms of demand.
              Specifically, this profession is among the 10 most in demand this
              year in many countries. It is also noteworthy that the
              professional area with the most obstacles to overcome when it
              comes to finding professionals is software development. This is
              because it specializes in the finding of solutions through the use
              of advanced software coding skills and a host of soft skills too.
            </p>
            <p>
              The software developer profile is a profile that encompasses other
              more specific profiles, among which one stands out for its focus
              on databases, back-end logic, application programming interface
              (APIs), architecture, and servers. They use code that helps
              browsers communicate with databases, store, understand, and delete
              data.
            </p>
            <p>
              Back End developers are among the most sought-after professionals.
              Back-end developer RANKED #2 among the top-10 IT jobs in demand in
              2020. It is a necessary role in all those businesses that requires
              professionals with comprehensive knowledge of what goes on on the
              server side of the software system. These back end components are
              build so they interact seamlessly with the front end and on the
              other end with databases and other storage systems.
            </p>
            <p>
              Development, maintenance and adaptation of the company's software
              back ends to employees or customers are crucial to any business.
              The correct way of doing it in great measure determines the
              business logic and security of sensitive data and application
              interfaces.
            </p>
            <p>
              The versatility of the back end software developer within
              software, application and web development makes these
              professionals highly demanded and economically valued. In fact,
              the average salary of a back end developer floats around 80.000
              USD per year. This figure can vary greatly depending on the
              developer's experience and country of deployment.
            </p>
            <p>
              But why would you need a back end developer if you allready have a
              full stack developer? Well unless you have a genius as a full
              stack developer the immersive role that the back end developer has
              with regards to the server side aspect of the software system lets
              him be more attuned to the implementation of the business logic
              and software performance than a full stack developer which has a
              more broad scope. Full stack developer have a more comprehensive
              view, while the back end developer has a more laser focus on the
              back end.
            </p>
            <h4>Training</h4>
            <p>
              The training of this type of profiles is important because of the
              existing demand, but also because it is a speciality that is
              included within the Information and Communication Technologies
              (ICT) sector, which is constantly evolving and changing.
              Currently, there are many training centers that offer the
              possibility of starting a career as a back end developer. A clear
              example is MIT's online learning course which currently offers an
              11 week back end development course , which will provide the
              opportunity to master back end development.
            </p>
            <h4>Eagerness to learn</h4>
            <p>
              Being a good back end developer requires being constantly updated
              on new tools and trends that appear in the world of software
              development. Being skilled in all the tasks that this type of work
              requires will make the developer a very interesting person to
              count on.
            </p>
            <p>
              Starting from scratch as a back end developer is complicated if
              there is no previous background in coding.
            </p>
            <p>
              Basic concepts like the difference between front end and back end,
              along with problem solving skills are a first step to start on
              your journey.
            </p>
            <h4>Technical know how</h4>
            <p>
              Being a back end developer does not mean that you know every back
              end programming language there is, or that you know all the frame
              works that support those programming languages or that you know
              how to install and configure every type of webserver there is. The
              knowledge of a back end software engineer runs vertically along
              the technology stack and is not all encompassing. The particular
              set of technical skills depends of the sector and the scope and
              size of the software system and least of not all the preference of
              the development team or project managers. That is why you can have
              two back end software developers each knowing completely different
              things.
            </p>
            <p>Looking at the figure below one can see how this works.</p>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default BackEndArticle;
