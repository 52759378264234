import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function SectionGreen() {
  return (
    <div className="features-4">
      <Container id="green-developers">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">
              We are green Software Developers in Latin America. Stopping
              Climate Change is important to us.
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <div className="ipad-container" style={{ paddingTop: "40px" }}>
              <img
                alt="..."
                src={require("assets/img/sections/green-coffe.jpg")}
                style={{ borderRadius: "20px" }}
              />
            </div>
          </Col>
          <Col md="8">
            <div className="info-horizontal">
              <div className="description" style={{ "textAlign": "justify" }}>
                <h5 className="description">
                  At code Latam we adhere as much as possible to the eight
                  principles of green software development:
                </h5>
                <ol>
                  <li>
                    <h5>
                      Carbon. Developers should build software that offers value
                      to users, while producing fewer carbon emissions.
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Electricity. Energy-efficient applications use software
                      components that don't consume much energy.
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Carbon intensity. Intensity refers to the amount of carbon
                      emissions that is generated for every kilowatt per hour of
                      electricity that's used. Companies should consume as much
                      electricity as possible from renewable energy sources,
                      which have lower carbon intensity.
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Embodied, or embedded, carbon. Embodied carbon is how much
                      carbon is released when companies develop and dispose of
                      electronic devices. This principle calls for organizations
                      to build software that reduces the amount of carbon that's
                      released when they develop and dispose of electronic
                      devices.
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Energy proportionality. Energy proportionality is the
                      relationship between how much a device is used and the
                      electricity the device uses. The more someone uses a
                      device, the better it can{" "}
                      <Link to="/green-developers" tag={Link}>
                        read more...
                      </Link>
                    </h5>
                  </li>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionGreen;
