import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function AlternativeHeader({ title, subTitle, imageUrl }) {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage: imageUrl,
        }}
      >
        <div className="filter filter-success" />
        <div className="content-center">
          <Container>
            <h1>{title}</h1>
            <h3>{subTitle}</h3>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AlternativeHeader;
