import { useEffect } from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import Navbar from "components/Navbars/PrincipalNavbar";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

function FrontEndArticle() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });

  return (
    <>
      <Navbar isIndexPage={false} />
      <AlternativeHeader
        title="The Front End software developer."
        imageUrl={`url(${require("assets/img/sections/front-end-developer.jpg")})`}
      />
      <div className="main">
        <div className="section">
          <Container style={{ "textAlign": "justify" }}>
            <p>
              Among the different types of jobs with more demand in the USA and
              Europe, those related to Information and Communication
              Technologies stand out. This is also true for Latin America. The
              technology sector in the world seems to be understaffed. There are
              some 120,400 vacancies in Colombia alone for example.
            </p>
            <p>
              Within this sector, the role of the Software Developer is one of
              the profiles that will remain on the rise in terms of demand.
              Specifically, this profession is among the 10 most in demand this
              year in many countries.
            </p>
            <p>
              It is also noteworthy that the professional area with the most
              obstacles to overcome when it comes to finding professionals is
              software development. This is because it specializes in the
              finding of solutions through the use of advanced software coding
              skills and a host of soft skills too.
            </p>
            <p>
              The software developer profile is a profile that encompasses other
              more specific profiles, among which one stands out for its focus
              on the user experience and front end interactions: The Front End
              Developer.
            </p>
            <p>
              Front End developers are among the most sought-after
              professionals. It is a necessary role in all those businesses that
              requires professionals with comprehensive knowledge of user
              friendly interfaces, and how to build them. These front ends are
              built so they interact seamlessly with the back end. Usually the
              back end than interacts with underlying databases. The user
              interfaces also shields the front end users from the complexities
              of the back end.
            </p>
            <p>
              Development, maintenance and adaptation of the company's software
              front ends to employees or customers are crucial to any business.
              The correct way of doing it in great measures determines success.
              The versatility of the front end software developer within
              software, application and web development makes these
              professionals highly demanded and economically valued. In fact,
              the average salary of a junior front end developer floats around
              50.000 USD per year. This figure can vary greatly depending on the
              developer's experience and country of deployment.
            </p>
            <p>
              But why would you need a front end developer if you allready have
              a full stack developer? Well unless you have a genius as a full
              stack developer the immersive role that the front end developer
              has with regards to the user interface lets him be more attuned to
              the user experience than a full stack developer which has a more
              broad scope. Full stack developer have a more comprehensive view,
              while the front end developer has a more laser focus on the front
              end.
            </p>
            <h4>Training</h4>
            <p>
              The training of this type of profiles is important because of the
              existing demand, but also because it is a speciality that is
              included within the Information and Communication Technologies
              (ICT) sector, which is constantly evolving and changing.
            </p>
            <p>
              Currently, there are many training centers that offer the
              possibility of starting a career as a front end developer. A clear
              example is MIT's online learning course which currently offers an
              11 week front end development React course , which will provide
              the opportunity to master front end development using java script
              with React as a framework.
            </p>
            <h4>Eagerness to learn</h4>
            <p>
              Being a good front end developer requires being constantly updated
              on new tools and trends that appear in the world of software
              development. Being skilled in all the tasks that this type of work
              requires will make the developer a very interesting person to
              count on.
            </p>
            <p>
              Starting from scratch as a front end developer is complicated if
              there is no previous background in coding.
            </p>
            <p>
              Basic concepts like the difference between front end and back end,
              along with problem solving skills are a first step to start on
              your journey.
            </p>
            <h4>Technical know how</h4>
            <p>
              Being a front end developer does not mean that you know every
              front end programming language there is, or that you know all the
              frame works that support those programming languages. The
              knowledge of a front end engineer runs vertically along the
              technology stack and is not all encompassing. The particular set
              of technical skills depends of the sector and the scope and size
              of the software system and least of not all the preference of the
              development team or project managers. That is why you can have two
              front end software developers each knowing completely different
              things.
            </p>
            <p>Looking at the figure below one can see how this works.</p>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default FrontEndArticle;
