import React, { useState } from "react";
// react plugin used to create google maps

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

// Services
import { sendContactEmail } from "services/ServicesEmails";

function SectionContactUs() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = async () => {
    await sendContactEmail({
      firstName,
      lastName,
      email,
      message,
    });
    setFirstName("");
    setLastName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="section-contactus cd-section" id="contact-us">
      <div
        className="contactus-1 section-image"
        style={{
          backgroundImage: "url(" + require("assets/img/contact.jpg") + ")",
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card className="card-contact no-transition">
                <CardTitle className="text-center" tag="h2">
                  Contact Us
                </CardTitle>
                <Row>
                  <Col className="ml-auto" md="5">
                    <CardBody>
                      <div className="info info-horizontal">
                        <div className="icon icon-info">
                          <i className="nc-icon nc-pin-3" />
                        </div>
                        <div className="description">
                          <h4 className="info-title">Find us at the office</h4>
                          <p>
                            Calle 4 Sur 48 110 INT 456 <br />
                            Medellin, Colombia
                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal">
                        <div className="icon icon-danger">
                          <i className="nc-icon nc-badge" />
                        </div>
                        <div className="description">
                          <h4 className="info-title">Give us a ring</h4>
                          <p>
                            +57 300 425 7338 <br />
                            Mon - Fri, 8:00-17:00
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Col>
                  <Col className="mr-auto" md="5">
                    <Form>
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup className="label-floating">
                              <label className="control-label">
                                First name
                              </label>
                              <Input
                                id="firstName"
                                name="firstName"
                                placeholder="First Name"
                                type="text"
                                value={firstName}
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="label-floating">
                              <label className="control-label">Last name</label>
                              <Input
                                id="lastName"
                                name="lastName"
                                placeholder="Last Name"
                                type="text"
                                value={lastName}
                                required
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup className="label-floating">
                          <label className="control-label">Email address</label>
                          <Input
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup className="label-floating">
                          <label className="control-label">Your message</label>
                          <Input
                            id="message"
                            name="message"
                            placeholder="Message"
                            type="textarea"
                            rows="6"
                            value={message}
                            required
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </FormGroup>
                        <Row>
                          <Col md="6">
                            <Button
                              className="pull-right"
                              color="primary"
                              onClick={sendEmail}
                            >
                              Send Message
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default SectionContactUs;
