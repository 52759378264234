import {useEffect } from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components
import Navbar from "components/Navbars/PrincipalNavbar";

function Error422() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("error-422");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("error-422");
    };
  });
  return (
    <>
      <Navbar isIndexPage={false} />
      <div
        className="background-img"
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/jan-sendereks.jpg") + ")",
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <h1 className="title">
               422 <br />
              <p className="error-msg">The changes you wanted was rejected</p>
            </h1>
          </Row>
          <div className="container-cards">
            <Row>
              <h5 className="discover-pages text-center">You can discover:</h5>
              <br />
              <br />
              <br />
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Error422;
