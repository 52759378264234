import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

function SectionSupport() {
  return (
    <div className="testimonials-2 section section-testimonials">
      <Container id="time-support">
        <Row>
          <Col className="mr-auto" md="2">
            <div className="testimonials-people">
              <img
                alt="..."
                className="left-first-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197430.png"}
              />
              <img
                alt="..."
                className="left-second-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197560.png"}
              />
              <img
                alt="..."
                className="left-third-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197484.png"}
              />
              <img
                alt="..."
                className="left-fourth-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197631.png"}
              />
              <img
                alt="..."
                className="left-fifth-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197573.png"}
              />
              <img
                alt="..."
                className="left-sixth-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197575.png"}
              />
            </div>
          </Col>
          <Col className="ml-auto mr-auto" md="8">
            <div className="page-carousel">
              <Card className="card-testimonial card-plain">
                <div className="card-avatar">
                  <img
                    alt="..."
                    className="img"
                    src={
                      "https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/51gddoP5rcL.jpg"
                    }
                  />
                </div>
                <CardBody>
                  <CardFooter>
                    <CardTitle tag="h2">
                      At Code Latam our Latin American software developers
                      support all time zones in an offshore or remote setting.
                    </CardTitle>
                  </CardFooter>
                  <h5 className="description pt-4">
                    No matter where your organization is located, we have a team
                    of Software Developers ready for you. They can work as a
                    hired specialist in a project that you manage or they can
                    work in a project that you let us manage using our Agile
                    SCRUM framework. Our developers are from different time
                    zones and can adapt themselves to your working hours or if
                    you wish they can work when you sleep! let us know what you
                    prefer by contacting us.
                  </h5>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col className="ml-auto" md="2">
            <div className="testimonials-people">
              <img
                alt="..."
                className="right-first-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197386.png"}
              />
              <img
                alt="..."
                className="right-second-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197441.png"}
              />
              <img
                alt="..."
                className="right-third-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197496.png"}
              />
              <img
                alt="..."
                className="right-fourth-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197571.png"}
              />
              <img
                alt="..."
                className="right-fifth-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197408.png"}
              />
              <img
                alt="..."
                className="right-sixth-person add-animation"
                src={"https://cdn-icons-png.flaticon.com/512/197/197525.png"}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionSupport;
