import { useEffect } from "react";
// reactstrap components

// core components
import FooterBlack from "components/Footers/FooterBlack.js";
// sections for this page
import SectionDevelopers from "./Sections/SectionDevelopers.js";
import SectionTeam from "./Sections/SectionTeam";
import SectionTecnologies from "./Sections/SectionTecnologies.js";
import SectionCourses from "./Sections/SectionCourses";
import SectionArticles from "./Sections/SectionArticles";
import SectionSupport from "./Sections/SectionSupport";
import SectionContactUs from "./Sections/SectionContactUs.js";
import SectionGreen from "./Sections/SectionGreen.js";
import Navbar from "components/Navbars/PrincipalNavbar";
import IndexHeader from "components/Headers/IndexHeader";

function Sections() {
  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "co-index";
    script.src = "https://cdn.oncehub.com/co/widget.js";
    script["data-co-params"] = "website_id=WEB-F79DDBCC6E";
    script.async = true;

    document.body.appendChild(script);

    document.body.classList.add("section-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    const updateView = () => {
      var contentSections = document.getElementsByClassName("cd-section");
      var navigationItems = document
        .getElementById("cd-vertical-nav")
        .getElementsByTagName("a");

      for (let i = 0; i < contentSections.length; i++) {
        var activeSection =
          parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
        if (
          contentSections[i].offsetTop - window.innerHeight / 2 <
            window.pageYOffset &&
          contentSections[i].offsetTop +
            contentSections[i].scrollHeight -
            window.innerHeight / 2 >
            window.pageYOffset
        ) {
          navigationItems[activeSection].classList.add("is-selected");
        } else {
          navigationItems[activeSection].classList.remove("is-selected");
        }
      }
    };

    window.addEventListener("scroll", updateView);
    return function cleanup() {
      document.body.classList.remove("section-page");
      window.removeEventListener("scroll", updateView);
    };
  });

  return (
    <>
      <Navbar isIndexPage={true} />
      <IndexHeader />
      <SectionDevelopers />
      <SectionTecnologies />
      <SectionTeam />
      <SectionCourses />
      <SectionSupport />
      <SectionArticles />
      <SectionGreen />
      <SectionContactUs />
      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              data-number="1"
              href="#headers"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("headers").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Code Latam</span>
            </a>
          </li>
          <li>
            <a
              data-number="2"
              href="#developers-startups"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("developers-startups").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Developers for Startups</span>
            </a>
          </li>
          <li>
            <a
              data-number="3"
              href="#technology-stack"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("technology-stack").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Technology stack</span>
            </a>
          </li>
          <li>
            <a
              data-number="4"
              href="#teams"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("teams").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Our Team</span>
            </a>
          </li>
          <li>
            <a
              data-number="5"
              href="#courses"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("courses").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Courses for Developers</span>
            </a>
          </li>
          <li>
            <a
              data-number="6"
              href="#time-support"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("time-support").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">All time support</span>
            </a>
          </li>
          <li>
            <a
              data-number="7"
              href="#articles"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("articles").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Articles</span>
            </a>
          </li>
          <li>
            <a
              data-number="8"
              href="#green-developers"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("green-developers").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Green Developers</span>
            </a>
          </li>
          <li>
            <a
              data-number="9"
              href="#contact-us"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("contact-us").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Contact Us</span>
            </a>
          </li>
        </ul>
      </nav>
      <FooterBlack />
    </>
  );
}

export default Sections;
