import { useEffect } from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import Navbar from "components/Navbars/PrincipalNavbar";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

function GreatOffshoringArticle() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });

  return (
    <>
      <Navbar isIndexPage={false} />
      <AlternativeHeader
        title="The great offshoring of software developers to Latin America."
        imageUrl={`url(${require("assets/img/sections/work-from-home.jpg")})`}
      />
      <div className="main">
        <div className="section">
          <Container style={{ "textAlign": "justify" }}>
            <p>
              "I work from home for the UK for €100,000 and I'm happy". More and
              more U.S. and European companies are signing up software
              development talent in South America to work remotely with.
            </p>
            <p>
              "I love kitesurfing, so some days I come to the beach with my
              laptop, do some coding from the car and as soon as the wind picks
              up, I jump in. That's priceless. This is how Adrià Cidre, a
              software engineer, sums up one of the greatest advantages of his
              current job. And he says it's not the only one. "You miss not
              having so much contact with other software developers in the
              office, but I see it as another advantage, it's time I spend with
              the people I love, with my family," he explains to this newspaper
              from his home in Cartagena, a place he moved to five years ago and
              from which, for the moment, he does not want to leave. From the
              'kitesurfing' paradise, Cidre works for a London-based software
              development company that pays him a salary that is unaffordable
              for most Latin America firms. "I make between 90,000 and 100,000
              and I'm happy," he laughs, "Before I wouldn't make 50,000 in the
              same position for a company here. Now tell me if it makes sense or
              not."
            </p>
            <p>
              Cidre is one of many software engineers who have long been working
              100% remotely from Colombia for companies based outside our
              country, especially in the US and UK. It used to be common in the
              technology sector and in certain technical positions, but now the
              coronavirus pandemic has brutally expanded this trend. According
              to the European Commission's Joint Research Center, 40% of
              European workers have switched to full-time telecommuting as a
              result of the pandemic, compared to only 15% before the pandemic.
              Being cooped up at home pulling Zoom for almost a year has become
              a nightmare for some, but a lucrative new job opportunity for
              others.
            </p>
            <h4>Code Latam</h4>
            <p>
              Welcome to Code Latam. You are reading Code Latam's, series of
              analysis articles on the big tech topics of the moment. This is
              the first installment of a new series dedicated to the telework
              phenomenon in the wake of the coronavirus pandemic. The technology
              sector and specifically software development is the one most
              affected by this trend, and the one that is going to mark, for
              better and for worse, the way to the rest of the big companies and
              startups.
            </p>
            <h4>Head Hunters</h4>
            <p>
              "More and more candidates are writing to me in Argentina asking me
              if I know international firms that are looking for remote
              developers. Before the pandemic, there was interest in working for
              a company abroad, not only because of the salary, but also because
              of cultural issues, to have different inputs in your career, to
              give your CV a boost... But now, with telecommuting, it has
              increased. On the one hand, you have many more companies willing
              to hire remote programmers, and on the other, candidates who are
              looking for it," explains Rogelio Arango, a headhunter
              specializing in startups and the technology sector.
            </p>
            <p>
              The movement is unstoppable: more than 20 million people in South
              America have been forced to telework during 2020 as a result of
              the pandemic, almost four times more than those who did so in
              2019, according to INE data. It is 16% of the total number of
              employed, when in 2019 it did not even reach 5%. And the result
              has been rather unhinged: we have ended up throwing more time and
              in a more chaotic way than before charging the same or less.
              Proof: 54% of Brazilian workers believe that the pandemic and
              teleworking have led them to work more unpaid overtime than before
              covid, according to a recent study by Hays.
            </p>
            <h4>Remote working culture in Latin America</h4>
            <p>
              In Latin America, the situation has been complex, due to the
              absence of a teleworking culture. While in Colombia for instance
              the number of employees who teleworked before the pandemic was
              only around 5%, in the Netherlands and Finland it was already
              close to 15%. This leap into the void has meant that certain
              profiles, especially highly qualified technical professionals,
              have ended up asking themselves the same question: why work from
              sunrise to sunset from home for a South American company for a
              salary below the international market when you could earn much
              more for a company based abroad and dedicating fewer hours?
            </p>
            <p>
              That is exactly what Alejandro Such asked himself in the middle of
              the pandemic. This Medellin based remote software developer was
              working for several South American companies until last July, but
              confinement and covid made him rethink his priorities. "I needed a
              change and I got two very interesting offers. One from a Medellin
              company to work here in Medellin and another from a US startup
              company to be 100% remote. Because they paid me much more, I
              accepted the startup's offer.
            </p>
            <p>
              Such became part of a team of remote software engineers spread
              around the world, from Los Angeles to India to Nigeria. "I felt
              great, it was an organization already used to working remotely and
              that shows, it makes a difference. Everything was designed to make
              the work efficient, I had normal working hours, from 9:00 to 17:30
              or 18:00, and I could combine my personal life with my work. The
              family issue for me is a turning point in favor of teleworking,
              but you have to be in a company that understands and encourages
              it".
            </p>
            <p>
              Just four months later, Such was called again, this time by
              another U.S. firm, Axosoft, but to set up his first office in
              Medellin and lead the project. It was another great opportunity,
              although I would go back to the telecommuting model without a
              problem. The only drawback I see is that I think it is more
              complicated to develop professionally remotely and get to lead
              teams," he explains.
            </p>
            <p>
              This was not the case for Alejandro González. This Argentinian is
              director of software engineering at Team Satchel, a London-based
              firm that created an online education platform used by thousands
              of schools in the United Kingdom. He manages a team of 22
              engineers spread across half the world. All of them are remote,
              like him, who works from his home in Buenos Aires. González
              prefers not to reveal his salary, but he assures that it is 30%
              higher than what he was paid in Argentina for the same position,
              team and responsibility, and with on-site presence. A quick search
              is enough to verify that these salaries are between 100,000 and
              150,000 euros.
            </p>
            <p>
              "It was clear to me that I wanted something remote. I hate wasting
              time going to the office. I now go once a quarter to London, see
              everyone and the rest of the time I'm in Buenos Aires. I tell my
              team something similar: "I only need all of us to coincide two
              hours a day, the rest of the time, suit yourselves as you like,"
              he explains. González assures that in his environment he is seeing
              more of the same. "All the colleagues I have in Buenos Aires are
              the same, they have decided that in order to work remotely for a
              company here, it is better to work for one in Denmark or the UK.
              Of course, not everyone is good enough. You have to be practically
              bilingual and have experience". The change in trend is so strong
              that the sector is already starting to talk about a new wave of
              offshoring.
            </p>
            <h4>Unpayable wages for South American companies</h4>
            <p>
              In the mid-2000s, the world experienced the outsourcing boom:
              thousands of US and European multinationals began to outsource a
              large part of their technical and administrative services to Asian
              countries, especially India. In Latin America, this wave arrived
              much later, and in a very different way. Instead of hiring call
              centers and programmers in India, because of the language barrier,
              we started to do it in Latin America. Now, almost two decades
              later, the rise of teleworking and a pandemic are creating another
              new wave of offshoring, but at a different level: US and European
              companies looking for top-notch technological talent in countries
              like Colombia to work remotely. No matter how much they are paid,
              the salary will still be lower than what they would have to pay in
              their respective countries, but between 15% and 30% higher than
              Colombian salaries. Impossible to compete.
            </p>
            <h4>The new offshoring</h4>
            <p>
              "Clearly, a new type of offshoring is taking place, but now the
              focus is on talent, you no longer 'outsource' just for costs. Now
              you are looking for the best people, even if on the way you save
              some money by having them remotely", explains Carlos Reinés from
              New York. This Spanish engineer created RubiconMD, a telemedicine
              startup in the US seven years ago, and has now opened a subsidiary
              to hire remote engineers. "We are not going to have an office, but
              we offer all the facilities to work from coworkings or wherever
              the candidate wants," he says. In this way, he solves a problem
              that puts off many candidates in South America: having to work as
              a freelancer for a firm based abroad.
            </p>
            <p>
              The model of remote engineers has caught on so well in the US that
              there are already all kinds of organizations helping to hire them,
              from specialized 'headhunters' to 'startups' such as Code Latam.
            </p>
            <h4>Difficulties</h4>
            <p>
              Rogelio Arango warns: all that glitters is not gold. "You have to
              be very aware that working for companies abroad as a freelancer
              doesn't work for everyone and it can become very hard. If you work
              for the U.S., time zone differences are going to be a problem.
              Your English has to be perfect. And you're going to have to get
              used to being alone. In exchange, it's true, there are many other
              advantages," he explains. But perhaps the biggest drawback is not
              for the candidates, but for the South American companies
              themselves.
            </p>
            <p>
              Fishing for talent at an international level has a direct
              implication: competition in salaries will also be global. And here
              most Latin American firms are being left out of the game, unable
              to match the salary offers they receive from abroad. "I am very
              concerned that this trend will end up stifling our companies,
              because without this talent they will not be able to continue
              improving," says Arango. What to do? "They have no choice but to
              change the mentality," points out Alejandro González. "Introducing
              four-day work weeks, having a generous vacation, sick and
              paternity leave policy, and making it as easy as possible to
              reconcile work and family life are key fronts. My company, for
              example, gives me only two weeks' paternity leave when in my
              country there are already four months. But then Casebook, the U.S.
              firm, put $100,000 on the table and it was more complicated to say
              no," he laughs on the other end of the phone.
            </p>
            <h4>Six-figure salaries.</h4>
            <p>
              The pandemic has accelerated this change that threatens South
              American companies.
            </p>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default GreatOffshoringArticle;
