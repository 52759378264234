// reactstrap components
import { Card, CardBody, Container, Row, Col } from "reactstrap";

function SectionTecnologies() {
  return (
      <div className="pricing-4 section section-dark">
        <Container id="technology-stack">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <h2 className="title">
                Our offshore Latin American Developers are experts in various
                technologies. Their expertise taken as a whole provides the
                technology stack offered at Code Latam.
              </h2>
              <h5 className="description">
                Our expertise makes us able to give the customer just what it
                needs. On the other hand, we understand that not every software
                development project should be tackled with the same technology
                that you used in the last project. By selecting the wrong stack
                you run the risk of over or under engineering your software
                product. As mentioned our team support a variety of technology
                stacks balancing performance, quality and budget for our
                customer. Please take a look and let us know what you need:
              </h5>
            </Col>
          </Row>
          <div className="space-top" />
          <Row>
            <Col md="2">
              <Card className="card-pricing card-plain">
                <CardBody>
                  <h5 className="card-category text-success">FRONT END</h5>
                  <ul className="text-white">
                    <li>HTML, CSS, Javascript, C, C++ & C#</li>
                    <li>React, Vue, Angular & webpack</li>
                    <li>Bootstrap & Material UI</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="2">
              <Card className="card-pricing card-plain">
                <CardBody>
                  <h5 className="card-category text-info">BACK END</h5>
                  <ul className="text-white">
                    <li>PHP, Node, Ruby on rails, java, ASP.NET & Redis</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="2">
              <Card className="card-pricing card-plain">
                <CardBody>
                  <h5 className="card-category text-warning">DATABASE</h5>
                  <ul className="text-white">
                    <li>MSSQL, MySql & Postgres</li>
                    <li>MongoDB, Casandra, CouchDB & Elastic Search</li>
                    <li>Neo4j & ArangoDB</li>
                    <li>Kafka, SQS, ZeroMQ & RabbitMQ</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="2">
              <Card className="card-pricing card-plain">
                <CardBody>
                  <h5 className="card-category text-danger">DEV OPS</h5>
                  <ul className="text-white">
                    <li>NGINX, AWS, AZURE & ELK</li>
                    <li>Ansible, chef & Jenkins</li>
                    <li>Docker, Bladecenter, Kubemeters, Vagrant & VMWare</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="2">
              <Card className="card-pricing card-plain">
                <CardBody>
                  <h5 className="card-category text-gray">MOBILE</h5>
                  <ul className="text-white">
                    <li>Java & SDK</li>
                    <li>Objective-C & Swift</li>
                    <li>React Native, Ionic, PWA, Xamarin & Unity</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="2">
              <Card className="card-pricing card-plain">
                <CardBody>
                  <h5 className="card-category text-purple">AGILE PROJECT</h5>
                  <ul className="text-white">
                    <li>SCRUM</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
  );
}

export default SectionTecnologies;
