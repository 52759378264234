import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

import reportWebVitals from "reportWebVitals";

// pages
import Presentation from "pages/Presentation";
import AboutUs from "pages/AboutUs";
import GreatOffshoringArticle from "pages/Articles/GreatOffshoringArticle";
import FullStackArticle from "pages/Articles/FullStackArticle";
import FrontEndArticle from "pages/Articles/FrontEndArticle";
import BackEndArticle from "pages/Articles/BackEndArticle";
import DBAdminArticle from "pages/Articles/DBAdminArticle";
import RemoteDevelopmentArticle from "pages/Articles/RemoteDevelopmentArticle";
import GreenDevelopers from "pages/Articles/GreenDevelopers";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Error404 from "pages/Errors/Error404.js";
import Error422 from "pages/Errors/Error422.js";
import Error500 from "pages/Errors/Error500.js";
// others

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Presentation />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/great-offshoring" element={<GreatOffshoringArticle />} />
      <Route path="/full-stack-develoer" element={<FullStackArticle />} />
      <Route path="/front-end-develoer" element={<FrontEndArticle />} />
      <Route path="/back-end-develoer" element={<BackEndArticle />} />
      <Route path="/data-base-administrator" element={<DBAdminArticle />} />
      <Route path="/remote-software-development" element={<RemoteDevelopmentArticle />} />
      <Route path="/green-developers" element={<GreenDevelopers />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/error-404" element={<Error404 />} />
      <Route path="/error-422" element={<Error422 />} />
      <Route path="/error-500" element={<Error500 />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
