import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

function SectionCourses() {
  return (
    <div className="projects-3" id="courses">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="6">
            <h2 className="title">
              Curso de inglés de code-latam, nivel A1 a C1.
            </h2>
            <h5 className="description">
              Nuestros cursos están adaptados al CEFR y diseñados
              específicamente para desarrolladores de software hispanohablantes.
              Los cursos son impartidos en directo por un profesor titulado. Son
              muy flexibles, asequibles y te permitimos comprar horas según te
              lo permita tu presupuesto.
            </h5>
          </Col>
        </Row>
        <div className="space-top" />
        <Row>
          <Col md="4">
            <Card className="card-pricing" data-color="blue">
              <CardBody>
                <div className="card-icon">
                  <span className="icon-simple">
                    <i className="nc-icon">A1</i>
                  </span>
                </div>
                <CardTitle tag="h3">Level Beginners English.</CardTitle>
                <p className="card-description">
                  Es capaz de comprender y utilizar expresiones cotidianas de
                  uso muy frecuente, así como frases sencillas destinadas a
                  satisfacer necesidades de tipo inmediato.
                </p>
                <CardFooter>
                  <Button
                    className="btn-neutral btn-round"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Choose
                  </Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-pricing" data-color="green">
              <CardBody>
                <div className="card-icon">
                  <span className="icon-simple">
                    <i className="nc-icon">A2</i>
                  </span>
                </div>
                <CardTitle tag="h3">Level Elementary English.</CardTitle>
                <p className="card-description">
                  Es capaz de comprender frases y expresiones de uso frecuente
                  relacionadas con áreas de experiencia que le son especialmente
                  relevantes.
                </p>
                <CardFooter>
                  <Button
                    className="btn-neutral btn-round"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Choose
                  </Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-pricing" data-color="yellow">
              <CardBody>
                <div className="card-icon">
                  <span className="icon-simple">
                    <i className="nc-icon">B1</i>
                  </span>
                </div>
                <CardTitle tag="h3">Intermediate English.</CardTitle>
                <p className="card-description">
                  Es capaz de comprender los puntos principales de textos claros
                  y en lengua estándar sobre cuestiones cotidianas relacionadas
                  con el trabajo, la escuela, el ocio, etc.
                </p>
                <CardFooter>
                  <Button
                    className="btn-neutral btn-round"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Choose
                  </Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-pricing" data-color="orange">
              <CardBody>
                <div className="card-icon">
                  <span className="icon-simple">
                    <i className="nc-icon">B2</i>
                  </span>
                </div>
                <CardTitle tag="h3">Upper Intermediate English.</CardTitle>
                <p className="card-description">
                  Es capaz de comprender las ideas principales de textos
                  complejos que traten de temas tanto concretos como abstractos,
                  incluidos los debates técnicos de su especialidad.
                </p>
                <CardFooter>
                  <Button
                    className="btn-neutral btn-round"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Choose
                  </Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-pricing" data-color="purple">
              <CardBody>
                <div className="card-icon">
                  <span className="icon-simple">
                    <i className="nc-icon">C1</i>
                  </span>
                </div>
                <CardTitle tag="h3">Advanced English.</CardTitle>
                <p className="card-description">
                  Es capaz de comprender una amplia variedad de textos extensos
                  y con cierto nivel de exigencia, así como reconocer en ellos
                  sentidos implícitos.
                </p>
                <CardFooter>
                  <Button
                    className="btn-neutral btn-round"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Choose
                  </Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionCourses;
